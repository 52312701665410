:root {
  --text-color: #005fac;
  --container-color: #ffffff99;
  --button-color: #005fac;
  --button-text-color: #f5f5f5;
  --hover-color: #0078cc;
  --hover-text-color: #f5f5f5;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
  color: var(--text-color);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  position: relative;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: center;
}

/* Navbar */

.nav-container {
  display: flex;
  height: 5vh;
  padding: 0.5rem;
  padding-left: 0;
  background-color: #ffffffbb;
  justify-content: space-between;
  align-items: center;
}

.nav-container ul {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  list-style: none;
  align-items: center;
  padding-right: 1rem;
}

.nav-link {
  color: var(--button-text-color);
  text-decoration: none;
  padding: 0.5rem 0.75rem;
  background-color: var(--button-color);
  border-radius: 0.5rem;
}

.nav-home {
  object-fit: cover;
  border-radius: 0.5rem;
}

.nav-home img {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.nav-container a:hover {
  background-color: var(--hover-color);
  color: var(--hover-text-color);
}

.nav-container .nav-home:hover {
  background-color: #ffffff00;
}

/* Landing */

.me-info {
  display: flex;
  justify-content: space-between;
  background-color: var(--container-color);
  padding: 1rem;
  margin: 1rem;
  width: fit-content;
  border-radius: 0.75rem;
  gap: 1rem;
}

.me-info img {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
  border-radius: 0.75rem;
}

.me-info .right-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0rem;
}

.me-info .buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.me-info a {
  width: fit-content;
  color: var(--button-text-color);
  padding: 0.5rem 0.75rem;
  background-color: var(--button-color);
  border-radius: 0.5rem;
  text-decoration: none;
}

.me-info a:hover {
  background-color: var(--hover-color);
  color: var(--hover-text-color);
}

/* about */

.about-info {
  display: flex;
  flex-direction: column;
  background-color: var(--container-color);
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 10rem; /* makes buttons not cut off */
  width: fit-content;
  border-radius: 0.75rem;
  gap: 1rem;
}

.section {
  display: flex;
  max-width: 50rem;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.section img {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
  border-radius: 0.75rem;
}

.text-content {
  display: flex;
  flex-direction: column;
}

.socials {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: fit-content;
  align-self: center;
}

.link-buttons,
.social-buttons {
  display: flex;
  gap: 0.5rem;
}

.about a {
  width: fit-content;
  color: var(--button-text-color);
  padding: 0.5rem 0.75rem;
  background-color: var(--button-color);
  border-radius: 0.5rem;
  text-decoration: none;
}

.about a:hover {
  background-color: var(--hover-color);
  color: var(--hover-text-color);
}

/* projects */

.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
  bottom: 0;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--container-color);
  padding: 1rem;
  border-radius: 0.75rem;
}

.project-card img.logo {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
  border-radius: 0.8rem;
}

.project-card img.project-image {
  width: 100%;
  height: 17rem;
  object-fit: contain;
  border-radius: 0.5rem;
}

.project-card h2 {
  margin: 0;
}

.project-card a {
  width: fit-content;
  color: var(--button-text-color);
  padding: 0.5rem 0.75rem;
  background-color: var(--button-color);
  border-radius: 0.5rem;
  text-decoration: none;
}

.project-card a:hover {
  background-color: var(--hover-color);
  color: var(--hover-text-color);
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .nav-container {
    height: auto;
    align-items: center;
    padding: 0.5rem;
  }

  .nav-container ul {
    flex-direction: row;
    gap: 1rem;
    padding: 0;
    width: 100%;
  }

  .nav-link {
    width: 100%;
    text-align: center;
  }

  .nav-home {
    margin-bottom: 0.5rem;
  }

  .nav-home img {
    width: 2rem;
    height: 2rem;
  }

  .no-mobile {
    display: none;
  }

  .me-info {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .me-info img {
    width: 100%;
    max-width: 18rem;
    height: auto;
  }

  .section {
    flex-direction: column;
    max-width: none;
  }

  .section img {
    width: 100%;
    max-width: 18rem;
    height: auto;
  }

  .socials {
    align-self: center;
  }

  .link-buttons,
  .social-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .project-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
